import './App.css';
import Buscador from './components/Buscador'
import Navbar from './components/Navbar'


function App() {
  return (
    <div className="App">
      <Navbar/>
      <Buscador/>
    </div>
  );
}

export default App;