import React from 'react'
import styles from './styles.module.css'
import { httpDigitalOceanStorage } from '../../httpMedia'
import bandera from '../../img/bandera.jpeg'






const Navbar = () => {

    return (
        <nav className={styles.navbar}>
            <img className={styles.bandera_dominicana} src={bandera} alt="" />
            <div className={styles.navbar_btn}>
                <ul>
                    <li><a className={styles.lupa_nav_btn} href='#'>
                        <img className={styles.logo_dominicana} src={`${httpDigitalOceanStorage}icons-01.svg`} alt="" />
                    </a></li>
                    <li><a className={styles.share_nav_btn} href='#'>
                        <img className={styles.logo_dominicana} src={`${httpDigitalOceanStorage}icons-02.svg`} alt="" />
                    </a></li>
                    <li><a className={styles.user_nav_btn} href='#'>
                        <img className={styles.logo_dominicana} src={`${httpDigitalOceanStorage}icons-03.svg`} alt="" />
                    </a></li>
                    <li>
                        <a className={styles.ingreso_registro} >
                            <img className={styles.logo_dominicana} src={`${httpDigitalOceanStorage}icons-04.svg`} alt="" />
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default Navbar













