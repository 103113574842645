import React, { useState, useEffect } from 'react'
import styles from './styles.module.css';
import { httpDigitalOceanStorage } from '../../httpMedia';
import { apiGet } from '../../api/index'
import respuesta from './../../datos.json'

const Buscador = () => {

    // const [respuesta, setRespuesta] = useState([])
    const [busqueda, setBusqueda] = useState('')


    // const obtenerDatos = () => {
    //     apiGet('http://localhost:3001/datosdominicana')
    //         .then(r => {
    //             if (r.status === 'OK') setRespuesta(r.data)
    //             else console.error('Error al recibir datos')
    //         })
    //         .catch(err => console.error(err))
    // }


    // useEffect(() => {
    //     obtenerDatos()
    // }, [])


    return (
        <div className={styles.buscador_container}>
            <div className={styles.contenido}>
                <img className={styles.logo_dominicana} src={`${httpDigitalOceanStorage}LogoEducacion2020.png`} alt="" />
                <h2>PORTAL DE TRANSPARENCIA</h2>
                <a href="#" >¿Qué es un portal de Transparencia?</a>
                <input type='text' placeholder='Buscar...' className={styles.input_buscador} onChange={e => setBusqueda(e.target.value)} />
                {/* <p className={styles.resultados}>Resultados ({resultadosFiltrados.length})</p> */}
                <ul>

                    {busqueda ?
                        respuesta.filter(item => {
                            return item.nombre.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(busqueda) ||
                            item.id == busqueda
                        }).map((item) =>
                            <table>
                                <tr>
                                    <th>Nombre</th>
                                </tr>
                                <tr>
                                    <td key={item.id}>{'Nombre: ' + item.nombre} </td>
                                </tr>
                                <tr>
                                    <td>{ 'Apellido: ' +item.apellido}</td>
                                </tr>
                                <tr>
                                    <td>{'Ciudad: ' + item.ciudad}</td>
                                </tr>
                                <tr>
                                    <td>{'Telefono: ' + item.telefono}</td>
                                </tr>
                                <tr>
                                    <td>{'Escuela: ' + item.escuela}</td>
                                </tr>
                                <tr>
                                    <td> {'Mail: ' + item.mail}</td>
                                </tr>
                            </table >
                        )
                        : null}
                </ul>

            </div>
        </div>
    )
}

export default Buscador