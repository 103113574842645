var headers = new Headers()
headers.append('Content-Type', 'application/json')
headers.append('Authorization', localStorage.getItem('Authorization'))

export const apiGet = url => {
	var headers = new Headers()
	headers.append('Content-Type', 'application/json')
	headers.append('Authorization', localStorage.getItem('Authorization'))
	return fetch(url, {
		method: 'GET',
		headers
	})
		.then(v => v.json())
		.then(data => data)
}

export const apiPut = (url, obj) => {
	var headers = new Headers()
	headers.append('Content-Type', 'application/json')
	headers.append('Authorization', localStorage.getItem('Authorization'))
	return fetch(`${url}`, {
		method: 'PUT',
		body: JSON.stringify(obj),
		headers
	})
		.then(v => v.json())
		.then(r => {
			if (r.error) {
				return Promise.reject(r.validation)
			}
			return r
		})
		.catch(r => {
			//console.log(r)
			return { status: 'err' }
		})
}

export const apiDelete = (url, obj) => {
	var headers = new Headers()
	headers.append('Content-Type', 'application/json')
	headers.append('Authorization', localStorage.getItem('Authorization'))
	return fetch(`${url}`, {
		method: 'DELETE',
		body: JSON.stringify(obj),
		headers
	})
		.then(v => v.json())
		.then(r => {
			if (r.error) {
				return Promise.reject(r.validation)
			}
			return r
		})
		.catch(r => {
			//console.log(r)
			return { status: 'err' }
		})
}

export const apiPost = (url, obj) => {
	var headers = new Headers()
	headers.append('Content-Type', 'application/json')
	headers.append('Authorization', localStorage.getItem('Authorization'))
	return fetch(`${url}`, {
		method: 'POST',
		body: JSON.stringify(obj),
		headers
	})
		.then(v => v.json())
		.then(r => {
			if (r.error) {
				return Promise.reject(r.validation)
			}
			return r
		})
		.catch(r => {
			//console.log(r)
			return { status: 'err' }
		})
}

export const apiGetWithParams = (url, params) => {
	var headers = new Headers()
	headers.append('Content-Type', 'application/json')
	headers.append('Authorization', localStorage.getItem('Authorization'))
	var toFetch = new URL(url)
	toFetch.search = new URLSearchParams(params)

	return fetch(toFetch, { method: 'GET', headers }).then(r => r.json()).catch(r => ({ status: 'err' }))
}